<template>
    <div>
        <div class="rsk-panel rsk-mb-m">
            <rsk-form v-model="form" :rules="rules" ref="formRef" cacheKey="tracache">
                <rsk-form-item label="研修ID" prop="trainId" annotation="（完全一致）">
                    <rsk-input max="10" v-model="form.trainId" />
                </rsk-form-item>
                <rsk-form-item isExpand label="研修名" prop="trainName" annotation="（部分一致）">
                    <rsk-input isExpand max="100" v-model="form.trainName" />
                </rsk-form-item>
                <rsk-form-item prop="trainTypeObject" label="分類・階層">
                    <div class="rsk-flex">
                        <div class="rsk-form rsk-icon-wrap">
                            <span class="rsk-icon rsk-icon--question-gray">
                            <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--bottom">
                                <span>指定した分類・階層に関連する研修を検索します
                                <br>指定と異なる分類・階層の研修が表示されることがあります</span>
                            </label>
                            </span>
                        </div>
                        <rsk-select v-model="form.trainTypeObject" label="分類・階層" :options="typeOptions" />
                    </div>
                </rsk-form-item>
                <rsk-form-item prop="trainSupplier" label="提供会社">
                        <rsk-select v-model="form.trainSupplier" label="提供会社" :options="supplierOptions" />
                </rsk-form-item>
                <rsk-form-item  label="開催日" v-if="showDate">
                    <div style="display: flex;align-items: center;" prop="openDtm">
                        <div class="rsk-form rsk-mr-xs">
                            <rsk-start-date v-model="form.openStartDtm" id="openStartDtm"/>
                        </div>
                        <div class="rsk-mr-xs">〜</div>
                        <div class="rsk-form rsk-mr-xs">
                            <rsk-end-date v-model="form.openEndDtm" id="openEndDtm"/>
                        </div>
                        <div class="rsk-ml-s" />
                         <rsk-checkbox v-model="form.oldCheckValue" :options="oldCheckValue" />
                    </div>
                    <div v-show="disErrFlagOpen" ><span style="color: red;font-size:12px">開催開始日 は正しい日付を入力してください。</span><br></div>
                    <span v-show="disErrFlagEnd" style="color: red;font-size:12px">開催終了日 は正しい日付を入力してください。</span>
                    <span v-show="disErrFlag" style="color: red;font-size:12px">開催日 の期間指定が正しくありません。</span>
                </rsk-form-item>
                <!-- <rsk-form-item prop="traAppFlg" label="申込者">
                        <rsk-checkbox v-model="form.traAppFlgValue" :options="mngflgValue" />
                </rsk-form-item> -->
            </rsk-form>

            <rsk-form-submit>
                <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>                
                <rsk-button isExpand @click="clear" type="tertiary">クリア</rsk-button>
            </rsk-form-submit>
        </div>
       
        <rsk-table 
        ref="tableRef"
        :buttons="tableButtons"
        :total="count" 
        :data="tableData"
        :titles="titles"
        cacheKey="tracache"            
        @init="tableConfigInit"
        @pageInfoChange="tableChange"
        @sortChange="tableChange"
        />
       
        <popTraComment ref="poptracommemtRefs" :fromWhere="6" :trainId="poptrainId" :usrId="popusrId" v-model="dialogFormVisible"></popTraComment>
    </div>
</template>
<script>
import { useRouter,useRoute } from 'vue-router'
import { ref,onMounted,watch,nextTick } from 'vue';
import { inject } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { dateFtt } from "@/utils/common";
import { checkInvalid } from "@/utils/validate";
import { dateDtmCheck } from "@/utils/dateCheck"
import { halfNum } from "@/utils/validateUtils";
import popTraComment from "../poptracommemt/index";
import { toFull } from "@/utils/editnew";

export default {
    components: {
        popTraComment
  },
    setup() {  
        const showDate = ref(true)
    
        function reload(){
        showDate.value = false
        nextTick(() => {
            showDate.value = true
        })
        } 
        const router = useRouter()
        const route = useRoute()
        const service = inject("service");
        const form = ref({
            oldCheckValue: [true],
            traAppFlgValue: [0,1],
            trainName: ''
        })
        const mngflgValue = ref([
        { value: 0, label: "利用者" },
        { value: 1, label: "管理者" },
        ]);
        const oldCheckValue = ref([
        {value:true, label:'過去開催分も含む'}
        ]);
        const poptrainId = ref();
        const popusrId = ref();
        const dialogFormVisible = ref(false);
        const disErrFlag = ref(false);
        const disErrFlagOpen = ref(false);
        const disErrFlagEnd = ref(false);

        const rules = ref({
        traAppFlg: [
            {
            validator: (rule, value, callback) => {
                if (form.value.traAppFlgValue.length == 0) {
                callback(new Error("申込者を選択してください。"));
                } else {
                callback();
                }
            },
            },
        ],
        trainId: [
            halfNum('ID は半角数字で入力してください。'),
            {
            validator: (rule, value, callback) => {
                const validateData = { invalidStr: value, isUnicoe: true };
                checkInvalid(validateData, service, callback, "ID");
            },
            },
        ],
        trainName: [
            {
            validator: (rule, value, callback) => {
                const validateData = { invalidStr: value, isUnicoe: true };
                checkInvalid(validateData, service, callback, "研修名");
            },
            },
        ],
        openDtm:[
            {
            validator: (rule, value, callback) => {
                let res = dateDtmCheck("openStartDtm","openEndDtm",form.value.openStartDtm,form.value.openEndDtm)
                disErrFlag.value = res.disErrFlag;
                disErrFlagOpen.value = res.disErrFlagOpen;
                disErrFlagEnd.value = res.disErrFlagEnd;
                if(res.disErrFlag == true || res.disErrFlagOpen == true || res.disErrFlagEnd == true) {
                  callback(new Error())
                }else {
                  callback()
              }           
            },
            },            
        ]
        });
        const formRef = ref();

        const titles = ref([
        {
            field:'trainId',
            title:'研修ID',
            order:'asc'
            
        },
        {
            field:'trainName',
            title:'研修名',
            order: "none"
        },
        // {
        //     field:'appUsr',
        //     title:'申込者',
        //     order: "none"
        // },
        {
            field:'studyDate',
            title:'研修日時',
            order: "none"
        },
        {
            field:'trainPlace',
            title:'研修場所',
            order: "none"
        },
        // {
        //     field:'trainAppNum',
        //     title:'申込人数',
        //     postfix: "人",
        //     order: "none"
        // },
        {
            field:'statusName',
            title:'出欠',
            order: "none"
        },
        {
            field:'userScore',
            title:'点数',
            postfix: "点",
            order: "none"
        }
    ])
    const tableRef = ref();

    const poptracommemtRefs = ref()
    const tableButtons = [
        {type:'tertialy',
        text:'コメント',
        icon:'copy-admin',
        show: (item) => {
          if (item.commentShowFlag == 0 && item.statusName != 'キャンセル') {
            return true;
          } else {
            return false;
          }
        },
        callBack: (item) => {
            if(item.commentPastFlag == 0) {
                alert("コメント入力期限を過ぎています。\nカスタマーセンターまでご連絡ください。");
                return;
            }
            poptrainId.value = item.trainId;
            popusrId.value = item.usrId;
            setTimeout(()=>{
                poptracommemtRefs.value.open();
            },0) 
        },             
        },
        {type:'error',
        text:'キャンセル',
        icon:'delete',
        show: (item) => {
            if (item.cancelShowFlag == 0) {
                return true;
            } else {
                return false;
            }
        },
        callBack: (item) => {
            if(item.cancelPastFlag == 0) {
                ElMessageBox.alert("キャンセル受付期限を過ぎています。<br />カスタマーセンターまでご連絡ください。",{
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            ElMessageBox.confirm(
                "キャンセルしますがよろしいですか？",
                {
                cancelButtonClass: "btn-custom-cancel" ,
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル',
                type: 'warning',
                }
            ).then(() => {
                let formdata = {trainId:item.trainId,usrId:item.usrId,commentPastFlag:item.commentPastFlag,rank: item.rank}
                service.trastudy.cancel(formdata).then((res) => {
                submit();
                ElMessage({
                    type:'success',
                    message: res,
                    showClose: true,
                    duration:3000
                })
                }).catch((error) => {
                    console.log(error);           
                });          
            })
        },      
        }
    ]
    const tableData = ref([]);
    const count = ref();

    const typeOptions = ref();
    const supplierOptions = ref();

    let sort = {}
    let paging = {}

    function tableConfigInit(sorts, p) {
      sort = sorts
      paging = p
    }
    
    var stopRequestFlag = false;
    function tableChange(sorts, p) {
      sort = sorts
      paging = p
      if(!stopRequestFlag){
        submit();
      }
    }    
       

        function submit() {
        formRef.value.validate(form.value).then((res) => {
            if(res) {
            let formdata = {condition:{...form.value,...sort,...paging},oldCheckValue:form.value.oldCheckValue,traAppFlgValue:form.value.traAppFlgValue}
            service.trastudy.search(formdata).then((res) => {
                count.value = res.count;
                if (count.value > 0) {
                        tableData.value = res.list;
                        for(let i = 0;i<res.list.length;i++) {
                            res.list[i].trainName = {
                                text: res.list[i].trainName,
                                callBack: (item) => {
                                     router.push({ name: 'trastudyShow',params: {trainId: res.list[i].trainId, rank: res.list[i].rank}})
                                }
                            }
                            if(res.list[i].openStartDtm == null && res.list[i].openEndDtm == null) {
                                tableData.value[i].studyDate = "";
                            }                        
                            if(res.list[i].openStartDtm != null && res.list[i].openEndDtm == null) {
                                tableData.value[i].studyDate = dateFtt("auto_time",res.list[i].openStartDtm,"en");
                            }
                            if(res.list[i].openStartDtm != null && res.list[i].openEndDtm != null) {
                                tableData.value[i].studyDate = dateFtt("auto_time",res.list[i].openStartDtm,"en") + "～" + dateFtt("auto_time",res.list[i].openEndDtm,"en");
                            }
                            if(res.list[i].statusName == 0) {
                                tableData.value[i].statusName = "出席";
                            }
                            if(res.list[i].statusName == 1) {
                                tableData.value[i].statusName = "欠席";
                            }
                            if(res.list[i].statusName == 2) {
                                res.list[i].disabled = true
                                tableData.value[i].statusName = "キャンセル";
                            }                                                                          
                        }
                } else {
                    tableData.value = {};
                }
                localStorage.setItem("traCondition",JSON.stringify(res.traCondition))
                })
                .catch((error) => {
                console.log(error);
                });
            }
        });            
        }

        function refresh() {
            form.value.trainName = toFull(form.value.trainName);
            tableRef.value.clear();
        }

        function clear() {
            form.value = {};
            form.value.oldCheckValue = [true];            
            form.value.traAppFlgValue = [0, 1];
            form.value.trainName = '';
            formRef.value.clear();
            reload();
            tableRef.value.clear(); 
            form.value.openStartDtm = '';
            form.value.openEndDtm = '';
            disErrFlagOpen.value = false;
            disErrFlagEnd.value = false;
            disErrFlag.value = false;
        }

        watch(()=>route.path,(to)=> {
            if(to !== '/trastudyShow') {
                stopRequestFlag = true
                formRef.value.clear();
                tableRef.value.clear();
            }
        })              


        onMounted(() => {
            service.common.typeObject().then((res) => {
                typeOptions.value = res;
            }).catch((error) => {
                console.log(error);
            });
            service.trastudy.index().then((res) => {
                supplierOptions.value = res;
            }).catch((error) => {
                console.log(error);
            });         
            submit();
        });        

        return {
            tableConfigInit,
            tableChange,            
            poptracommemtRefs,
            rules,formRef,
            tableRef,tableButtons,
            typeOptions,supplierOptions,
            poptrainId,popusrId,dialogFormVisible,
            form,
            oldCheckValue,
            mngflgValue,
            tableData,count,
            titles,
            submit,clear,
            showDate,
            refresh,
            disErrFlag,
            disErrFlagOpen,
            disErrFlagEnd
        }
    },
}
</script>