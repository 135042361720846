<template>
    <div class="content">
        <div class="rsk-px-xl rsk-pt-m">
            <div class="rsk-font-bold rsk-font-xl rsk-mb-s aaaaa">お知らせ</div>
            <div class="rsk-panel rsk-mb-xl">
                <rsk-form v-model="formData" :rules="rules" ref="formRef" cacheKey="usrinfCache">
                    <rsk-form-item isExpand label="お知らせタイトル" annotation="（部分一致）" prop="title">
                        <rsk-input max="50" isExpand v-model="formData.title" />  
                    </rsk-form-item>
                    <rsk-form-item isExpand label="お知らせ本文" annotation="（部分一致）" prop="content">
                        <rsk-input max="1000" isExpand v-model="formData.content" />  
                    </rsk-form-item>
                    <rsk-form-item label="掲載日" v-if="showDate">
                        <div style="display: flex;align-items: center;" prop="startWithEndDtm">
                            <div class="rsk-form rsk-mr-xs">
                                <rsk-start-date v-model="formData.startedDtm" id="startedDtm"/>
                            </div>
                            <div class="rsk-mr-xs">〜</div>
                            <div class="rsk-form rsk-mr-xs">
                                <rsk-end-date v-model="formData.finishedDtm" id="finishedDtm"/>
                            </div>
                        </div>
                        <div v-show="disErrFlagOpen" ><span style="color: red;font-size:12px">掲載開始日 は正しい日付を入力してください。</span><br></div>
                        <span v-show="disErrFlagEnd" style="color: red;font-size:12px">掲載終了日 は正しい日付を入力してください。</span>
                        <span v-show="disErrFlag" style="color: red;font-size:12px">掲載日 の期間指定が正しくありません。</span>
                    </rsk-form-item>
                    <rsk-form-item hintText="必須" label="ステータス" prop="status" isList>
                        <rsk-checkbox v-model="formData.status" :options="[{label:'未確認', value:'0'}, {label:'確認済', value:'1'}]" />
                    </rsk-form-item>
                </rsk-form>
                <rsk-form-submit>
                    <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
                    <rsk-button isExpand type="tertiary" @click="clear">クリア</rsk-button>
                </rsk-form-submit>
            </div>
            <rsk-table 
                ref="tableRef"
                :data="tableData" 
                :titles="titles" 
                :total="totalTable" 
                @sortChange="orderTable" 
                @pageInfoChange="changePage" 
                @init="exatableConfigInit" 
                cacheKey="usrinfCache" />
        </div>
    </div>
</template>

<script>
export { default } from "./js/usr_inf_view.js";
</script>

<style lang="scss" src="./css/usr_inf.scss" scoped />