<template>
  <div>
    <div class="rsk-panel rsk-mb-m">
      <el-button type="text" style="margin-left:90%;" @click="changeSearchShow">{{showText}}</el-button>
      <div v-if="searchShow">
        <rsk-form  v-model="form" :rules="rules" ref="formRef" cacheKey="lescache">
          <rsk-form-item
            isExpand
            label="ボックス名"
            annotation="（部分一致）"
          >
            <rsk-input isExpand v-model="form.lessonName" max="50"/>
          </rsk-form-item>
          <rsk-form-item label="研修開始日" v-if="showDate">
            <div style="display: flex; align-items: center"  prop="startDate">
              <div class="rsk-form rsk-mr-xs">
                <rsk-start1-date v-model="form.startDateFrom" id="startDateFrom"/>
              </div>
              <div class="rsk-mr-xs">〜</div>
              <div class="rsk-form rsk-mr-xs">
                <rsk-end1-date v-model="form.startDateTo" id="startDateTo"/>
              </div>
              <div class="rsk-form-control__annotation">
                ※指定期間に研修開始日を含む場合に検索結果に表示されます。
              </div>
            </div>
            <div v-show="disErrFlagOpen" ><span style="color: red;font-size:12px">研修開始開始日 は正しい日付を入力してください。</span><br></div>
            <span v-show="disErrFlagEnd" style="color: red;font-size:12px">研修開始終了日 は正しい日付を入力してください。</span>
            <span v-show="disErrFlag" style="color: red;font-size:12px">研修開始日 の期間指定が正しくありません。</span>
          </rsk-form-item>
          <rsk-form-item label="研修終了日" v-if="showDate">
            <div style="display: flex; align-items: center" prop="endDate">
              <div class="rsk-form rsk-mr-xs">
                <rsk-start-date v-model="form.endDateFrom" id="endDateFrom"/>
              </div>
              <div class="rsk-mr-xs">〜</div>
              <div class="rsk-form rsk-mr-xs">
                <rsk-end-date v-model="form.endDateTo" id="endDateTo"/>
              </div>
              <div class="rsk-form-control__annotation">
                ※指定期間に研修終了日を含む場合に検索結果に表示されます。
              </div>
            </div>
            <div v-show="disErrFlagOpen2" ><span style="color: red;font-size:12px">研修終了開始日 は正しい日付を入力してください。</span><br></div>
            <span v-show="disErrFlagEnd2" style="color: red;font-size:12px">研修終了終了日 は正しい日付を入力してください。</span>
            <span v-show="disErrFlag2" style="color: red;font-size:12px">研修終了日 の期間指定が正しくありません。</span>
          </rsk-form-item>
          <rsk-form-item hintText="必須" label="ステータス" prop="finishFlg" isList>
            <rsk-checkbox :options="finishFlgValue" v-model="form.finishedFlg"/>
          </rsk-form-item>
        </rsk-form>

        <rsk-form-submit>
          <rsk-button isExpand type="primary" @click="refresh()">検索</rsk-button>
          <rsk-button isExpand type="tertiary" @click="clear">クリア</rsk-button>
        </rsk-form-submit>
      </div>
    </div>
    
    <rsk-table
    ref="tableRef"
    cacheKey="lescache" 
    :titles="titles"
    :total="count"
    :data="tableData"      
    @init="tableConfigInit"
    @pageInfoChange="tableChange"
    @sortChange="tableChange"
    >
      <template #content>
        <div class="rsk-font-xs rsk-text-gray060">※ボックス内のすべての研修・課題を修了すると、ボックスのステータスが修了となります。</div>
      </template>
    </rsk-table>
    
  </div>
</template>
<script>
import { useRouter,useRoute } from 'vue-router'
import { ref,onMounted,watch,nextTick } from "vue";
import { inject } from "vue";
import { dateFtt } from "@/utils/common";
import { dateDtmCheck } from "@/utils/dateCheck"
export default {
  setup() {
    const router = useRouter();
    const route = useRoute()
    const form = ref({
      finishedFlg: ["0"]
    });
    const service = inject("service");
    const tableRef = ref();
    const finishFlgValue = ref([
      { value: "0", label: "未修了" },
      { value: "1", label: "修了" },
    ]);
    const showText = ref('検索条件非表示');
    const searchShow = ref(true);

    const rules = ref({
      finishFlg: [
        {
          validator: (rule, value, callback) => {
            if (form.value.finishedFlg.length == 0) {
              callback(new Error("ステータスを選択してください。"));
            } else {
              callback();
            }
          },
        },
      ],
      startDate:[
          {
            validator: (rule, value, callback) => {
                let res = dateDtmCheck("startDateFrom","startDateTo",form.value.startDateFrom,form.value.startDateTo)
                disErrFlag.value = res.disErrFlag;
                disErrFlagOpen.value = res.disErrFlagOpen;
                disErrFlagEnd.value = res.disErrFlagEnd;
                if(res.disErrFlag == true || res.disErrFlagOpen == true || res.disErrFlagEnd == true) {
                  callback(new Error())
                }else {
                  callback()
                }           
            },
          },            
      ],
      endDate:[
          {
            validator: (rule, value, callback) => {
                let res = dateDtmCheck("endDateFrom","endDateTo",form.value.endDateFrom,form.value.endDateTo)
                disErrFlag2.value = res.disErrFlag;
                disErrFlagOpen2.value = res.disErrFlagOpen;
                disErrFlagEnd2.value = res.disErrFlagEnd;
                if(res.disErrFlag == true || res.disErrFlagOpen == true || res.disErrFlagEnd == true) {
                  callback(new Error())
                }else {
                  callback()
                }         
            },
          },            
      ],        
    });
    const formRef = ref();

    const titles = ref([
      {
        field: "lessonName",
        title: "ボックス名",
        order: "none",
      },
      {
        field: "finishFlg",
        label: true,
        title: "ステータス",
        options: finishFlgValue.value,
        order: "none",
      },
      {
        field: "progress",
        title: "進捗",
        order: "none",
      },
      {
        field: "startDate",
        title: "研修開始日",
        order: "none",
      },
      {
        field: "endDate",
        title: "研修終了日",
        order: "none",
      },
    ]);
    const tableData = ref([]);
    const count = ref();

    const disErrFlag = ref(false);
    const disErrFlag2 = ref(false);
    const disErrFlagOpen = ref(false);
    const disErrFlagOpen2 = ref(false);
    const disErrFlagEnd = ref(false);
    const disErrFlagEnd2 = ref(false);
    let sort = {}
    let paging = {}
    function tableConfigInit(sorts, p) {
      sort = sorts
      paging = p
    }
    var stopRequestFlag = false;
    function tableChange(sorts, p) {
      sort = sorts
      paging = p
      if(!stopRequestFlag){
        submit();
      }
    }


    function submit() {
        formRef.value.validate(form.value).then((res) => {
        if(res) {
            let formdata = {condition:{...form.value,...sort,...paging},finishedFlg:form.value.finishedFlg};
            service.les.search(formdata).then((res) => {
                count.value = res.count;
                if(res.count > 0) {
                    for(let i =0;i<res.list.length;i++) {
                    if(res.list[i].studyflg == 1) {
                        res.list[i].lessonName = {
                        text: res.list[i].lessonName,
                            callBack: (item) => {
                              router.push({ name: 'lesDetail',params:{lessonId:res.list[i].lessonId}})      
                            }
                        }                      
                    }
                    res.list[i].startDate = dateFtt("auto",res.list[i].startDate,"en");
                    res.list[i].endDate = dateFtt("auto",res.list[i].endDate,"en");
                    }
                    tableData.value = res.list;
                } else {
                    tableData.value = {};
                }
            }).catch((error) => {
                console.log(error);           
            });
        }
        });
    }

    function refresh() {
        tableRef.value.clear();
    }

    function clear() {
      form.value = {};
      form.value.finishedFlg = ["0"];
      formRef.value.clear();
      reload();
      tableRef.value.clear();
      form.value.startDateFrom = '';
      form.value.startDateTo = '';
      form.value.endDateFrom = '';
      form.value.endDateTo = '';
      disErrFlag.value = false;
      disErrFlag2.value = false;
      disErrFlagOpen.value = false;
      disErrFlagOpen2.value = false;
      disErrFlagEnd.value = false;
      disErrFlagEnd2.value = false;
    }
    
    watch(()=>route.path,(to)=> {
        if(to !== '/lesDetail') {
          stopRequestFlag = true;
          formRef.value.clear();
        }
      })     

    function changeSearchShow(){
      searchShow.value = !searchShow.value;
      if(searchShow.value){
        showText.value = '検索条件非表示'
      } else {
        showText.value = '検索条件表示'
      }
    }

    onMounted(() => {
      localStorage.removeItem('fromTopUrl')
      submit();
    });

    const showDate = ref(true)
    
    function reload(){
      showDate.value = false
      nextTick(() => {
        showDate.value = true
      })
    }

    return {
    showText,
    searchShow,
    changeSearchShow,
    finishFlgValue,
    form,
    rules,
    formRef, 
    tableRef,   
    titles,tableData,count,tableConfigInit,tableChange,
    submit,clear,
    showDate,
    refresh,
    disErrFlag,
    disErrFlag2,
    disErrFlagOpen,
    disErrFlagOpen2,
    disErrFlagEnd,
    disErrFlagEnd2
    };
  },
};
</script>

<style scoped>
</style>