<template>
    <div class="privacy-box" v-if="notice">
    <div class="privacy-box-inner"><dt> 【個人情報の取扱いについて】</dt>
      <dd>
       <p class="privacy-info-text">リスクモンスター株式会社（以下、「当社」という）は、与信管理サービスや研修サービスをはじめ、当社サービスにおいて、お客様の個人情報を利用するにあたり、個人情報を適切に取り扱うことは社会的責務であると考えております。<br>
        つきましては、以下をご確認いただき、同意をいただきますようお願い申し上げます。同意いただけない場合は、本フォームへの入力はできませんので、予めご了承願います。 </p><br><br>
       <p class="privacy-info-text text-weight">１．個人情報の利用目的</p>
       <p class="privacy-info-text">当社は、収集した個人情報について、以下の目的で個人情報を利用させて頂くことがございます。 </p>
       <ul class="privacy-info-list text-padding-l">
          <li class="privacy-info-item">（１）当社グループ（下記３の「共同利用する者の範囲」に記載する全ての会社を意味する。以下同様。）の商品・サービスの提供その他それらに付随する諸対応のため</li>
          <li class="privacy-info-item">（２）当社グループの及び提携先企業等の新商品、新サービス、優待、イベント等に関する情報をご送付・ご案内するため</li>
          <li class="privacy-info-item">（３）当社グループへの各種お問い合わせ、資料請求等に関する対応等を行うため</li>
          <li class="privacy-info-item">（４）当社グループからのアンケート依頼、作品公募、市場調査・顧客動向分析、特定個人を識別できない統計的データの作成及びその公表のため</li>
          <li class="privacy-info-item">（５）当社グループのサービス・商品の改良や、新たなサービス・商品を開発するため</li>
       </ul><br/>
       <p class="privacy-info-text text-weight">２．個人情報を入力するに当たっての注意事項</p>
       <p class="privacy-info-text">当社サービス利用に際して当社に個人情報を提供されることは任意ですが、必要な情報が提供されていない場合、もしくは提供された情報に誤りがあった場合、お問い合わせ等にご対応ができない場合があります。</p><br/>
       <p class="privacy-info-text text-weight">３．当社グループにおける共同利用</p>
       <p class="privacy-info-text">当社は、会員様、お取引先様の個人情報を、上記の利用目的のため以下のとおり当社グループ内で共同利用いたします。この場合には、当社が当該情報の管理についての責任を負います。 </p>
       <div style="padding-left: 30px;">
        <table width="90%" style="border: solid 1px #000;">
          <tbody>
            <tr>
              <td width="45%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">共同利用する者の範囲</td>
              <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">リスクモンスター株式会社並びにその子会社（外国含む）及び関連会社</td>
            </tr>
            <tr>
              <td width="45%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">共同利用する個人情報の項目</td>
              <td width="55%" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">氏名、生年月日、性別、住所、電話番号、FAX番号、メールアドレス、勤務先の情報（会社名、所属部課、役職、住所、電話番号、FAX番号）、当社グループのサービスご利用実績</td>
            </tr>
            <tr>
              <td width="45%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">共同利用する個人情報の管理責任者</td>
              <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">リスクモンスター株式会社　セキュリティ向上委員会　委員長（東京都中央区日本橋二丁目１６号５号　代表取締役社長　藤本 太一）</td>
            </tr>
            <tr>
              <td width="45%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">お問い合わせ</td>
              <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">下記お問い合わせ窓口までお願いします。</td>
            </tr>
          </tbody>
        </table>
       </div><br>
       <p class="privacy-info-text text-weight">４．個人情報の第三者への提供</p>
       <p class="privacy-info-text">当社は、個人情報について以下の場合を除き、あらかじめご本人の同意を得ること無く個人情報を第三者に提供することはございません。 </p>
       <ul class="privacy-info-list text-padding-l">
          <li class="privacy-info-item">（１）法令にもとづく場合。</li>
          <li class="privacy-info-item">（２）人の生命、健康、財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
          <li class="privacy-info-item">（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
          <li class="privacy-info-item">（４）警察・裁判所等の公的機関又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき。</li>
          <li class="privacy-info-item">（５）利用目的の範囲内において当社の業務を委託する場合。</li>
          <li class="privacy-info-item">（６）当社プライバシーポリシーにしたがい当社グループ各社間で共同利用する場合。</li>
       </ul><br/>
       <p class="privacy-info-text text-weight">５．開示対象個人情報の開示について</p>
       <p class="privacy-info-text">当社は、ご本人様からお申し出があったときは、当社が指定する手続に基づいて、当社保有の個人情報の利用目的の通知・開示・内容の訂正・追加または削除・利用の停止・消去および第三者への提供の停止に応じます。開示等のお申し出については以下のお問い合わせ窓口にて受け付けいたします。なお、開示、利用目的の通知に置きましては手数料をいただくことがありますので、あらかじめ御了承ください。 </p><br/>
       <p class="privacy-info-text text-weight">６．個人情報に関するお問い合わせ先</p>
       <p class="privacy-info-text">
          〒103-0027　東京都中央区日本橋２-16-5　RMGビル<br>
          リスクモンスター株式会社　セキュリティ向上委員会　委員長<br>
          TEL：03-6214-0331<br>
          窓口受付時間；当社営業日の９：00～18：00
       </p><br><br><br>
      </dd>
    </div>
  </div>
</template>
<script>
    export default {
        props: {
            notice: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" src="./css/contact.scss" scoped />