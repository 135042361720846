<template>
    <div class="content">
        <rsk-container title="eラーニング詳細" :buttons="buttons">
            <div class="detail-text-box__header">
                <div>
                    <div class="rsk-page-title rsk-mb-xs">{{tableData.elearningName}}</div>
                    <div class="rsk-text-gray080 rsk-font-l">研修ID：{{tableData.elearningId}}</div>
                </div>
                <div>
                    <div class="rsk-flex rsk-mb-xs">
                        <div class="rsk-text-gray080 rsk-mr-s">分類・階層</div>
                        <div>
                            {{elearningType}}
                        </div>
                    </div>

                    <div class="rsk-flex rsk-items-center rsk-mb-xs">
                        <div class="rsk-text-gray080 rsk-mr-s">ユーザー評価</div>
                        <div class="rsk-flex rsk-items-center">
                            <div v-if="comprehensiveEvaluation == null" class="rsk-font-bold rsk-font-l rsk-mr-xxs">
                                評価対象外
                            </div>
                            <div v-else>
                                <div class="rsk-font-bold rsk-font-l rsk-mr-xxs">{{comprehensiveEvaluation}}</div>
                                <template v-if="starsNum >= 1">
                                    <span class="rsk-icon rsk-icon--star"></span>
                                </template>
                                <template v-if="starsNum >= 2">
                                    <span class="rsk-icon rsk-icon--star"></span>
                                </template>
                                <template v-if="starsNum >= 3">
                                    <span class="rsk-icon rsk-icon--star"></span>
                                </template>
                                <template v-if="starsNum >= 4">
                                    <span class="rsk-icon rsk-icon--star"></span>
                                </template>
                                <template v-if="starsNum >= 5">
                                    <span class="rsk-icon rsk-icon--star"></span>
                                </template>
                                <template v-if="starsNum < 5">
                                    <span class="rsk-icon rsk-icon--star-border"></span>
                                </template>
                                <template v-if="starsNum < 4">
                                    <span class="rsk-icon rsk-icon--star-border"></span>
                                </template>
                                <template v-if="starsNum < 3">
                                    <span class="rsk-icon rsk-icon--star-border"></span>
                                </template>
                                <template v-if="starsNum < 2">
                                    <span class="rsk-icon rsk-icon--star-border"></span>
                                </template>
                                <template v-if="starsNum < 1">
                                    <span class="rsk-icon rsk-icon--star-border"></span>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="rsk-flex rsk-items-center">
                        <template v-if="evaluationContent == 1">
                            <span class="rsk-icon rsk-icon--no1 rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                        <template v-if="evaluationContent == 2">
                            <span class="rsk-icon rsk-icon--content10000 rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                        <template v-if="evaluationContent == 3">
                            <span class="rsk-icon rsk-icon--hot rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                        <template v-if="evaluationContent == 4">
                            <span class="rsk-icon rsk-icon--content5000 rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                        <template v-if="evaluationContent == 5">
                            <span class="rsk-icon rsk-icon--like rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                        <template v-if="evaluationContent == 6">
                            <span class="rsk-icon rsk-icon--good rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                        <template v-if="evaluationContent == 7">
                            <span class="rsk-icon rsk-icon--new rsk-mr-xs"></span>
                            <span class="rsk-text-green rsk-font-bold">{{getLableEvaluationContent(evaluationContent)}}</span>
                        </template>
                    </div>

                </div>
            </div>
            <div class="detail-text-box__content" v-html="tableData.elearningInf">
            </div>
            <info-container>
                <info-content label="定価（税抜）" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <div v-if="tableData.fixedPrice == 0">0円</div>
                        <div v-else-if="tableData.loginRank > tableData.rank" v-text="formatMoney(tableData.fixedPrice) + '円'"></div>
                        <div v-else><s v-text="formatMoney(tableData.fixedPrice) + '円'"></s><br>（ご契約料金内でご利用いただけます）</div>
                    </div>
                </info-content>
                <info-content label="研修期間" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <div v-if="tableData.loginRank > tableData.rank">
                            <div v-if="tableData.studyTerm == '0'">期間制限無し</div>
                            <div v-else>{{tableData.studyTerm}}ヶ月</div>
                        </div>
                        <div v-else>
                            <div v-if="tableData.studyTerm == '0'">期間制限無し</div>
                            <div v-else><s>{{tableData.studyTerm}}ヶ月</s><br>（ご契約期間中にご利用いただけます）</div>
                        </div>
                    </div>
                </info-content>
                <info-content :label="'標準学習時間'" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <div v-if="tableData.baseStudyTime != '' && tableData.baseStudyTime != null">{{tableData.baseStudyTime}}時間</div>
                    </div>
                </info-content>
            </info-container>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>

            <info-container >
                <info-content label="学習形式" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <template v-for="(studyType, index) in tableData.studyTypeList" :key="index">
                            <div v-if="studyType == '1'">
                                <div class="rsk-mr-s">
                                    <div class="rsk-icon-circle-with-text">
                                        <div class="rsk-icon-circle">
                                            <span class="rsk-icon rsk-icon--white-text"></span>
                                        </div>
                                        テキスト
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="studyType == '2'">
                                <div class="rsk-mr-s">
                                    <div class="rsk-icon-circle-with-text">
                                        <div class="rsk-icon-circle">
                                            <span class="rsk-icon rsk-icon--white-movie"></span>
                                        </div>
                                        映像
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="studyType == '3'">
                                <div class="rsk-mr-s">
                                    <div class="rsk-icon-circle-with-text">
                                        <div class="rsk-icon-circle">
                                            <span class="rsk-icon rsk-icon--white-account-square"></span>
                                        </div>
                                        アニメーション
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </info-content>
                <info-content :label="'研修可能端末'" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <template v-for="(environment, index) in tableData.environmentList" :key="index">
                            <div v-if="environment == '1'">
                                <div class="rsk-mr-s">
                                    <div class="rsk-icon-circle-with-text">
                                        <div class="rsk-icon-circle">
                                            <span class="rsk-icon rsk-icon--white-pc"></span>
                                        </div>
                                        パソコン
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="environment == '2'">
                                <div class="rsk-mr-s">
                                    <div class="rsk-icon-circle-with-text">
                                        <div class="rsk-icon-circle">
                                            <span class="rsk-icon rsk-icon--white-mobile"></span>
                                        </div>
                                        スマートフォン
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="environment == '3'">
                                <div class="rsk-mr-s">
                                    <div class="rsk-icon-circle-with-text">
                                        <div class="rsk-icon-circle">
                                            <span class="rsk-icon rsk-icon--white-tablet"></span>
                                        </div>
                                        タブレット
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="rsk-mr-s">

                    </div>
                </info-content>
                <info-content :label="'種別'" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <template v-for="(specification, index) in tableData.specificationList" :key="index">
                            <div v-if="specification == '1'">
                                分割コース<br>
                            </div>
                            <div v-else-if="specification == '2'">
                                本文印刷<br>
                            </div>
                        </template>
                    </div>
                </info-content>
            </info-container>
            
            <div v-if="tableData.imgFileName">
                <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
                <info-container >
                    <info-content label="画面イメージ" style="align-items:normal">
                        <div class="rsk-mb-xs">
                            <span>画像をクリックすると拡大します</span>
                        </div>
                        <div class="image_file-container small">
                            <img :src="tableData.imgFileName" @click="imgDialogVisible = true" />
                        </div>
                        
                    </info-content>
                </info-container>
            </div>

            <el-dialog
                v-model="imgDialogVisible"
                width="1000px"
                :show-close="false"
                >
                <div class="img_dialog-content">
                    <img :src="tableData.imgFileName" />
                </div>
                <rsk-form-submit style="border: none">
                    <div class="rsk-form-button-wrapper__button">
                        <button type="button" class="rsk-button rsk-button--medium rsk-button--expand rsk-button--primary" @click="closeImgDialog">
                            閉じる
                        </button>
                    </div>
                </rsk-form-submit>
                
            </el-dialog>
        </rsk-container>
    </div>
</template>
<script>
import { ref, onBeforeMount, onMounted, onBeforeUnmount, inject} from 'vue';
import infoContent from "../../component/info-content";
import infoContainer from "../../component/info-container";
import infoHeader from "../../component/info-header";
import { ElMessageBox } from 'element-plus'
import input from '../../../../core/components/rsk/input.vue';
import { useRoute,useRouter } from 'vue-router'
export default {
    components: {
        infoHeader,
        infoContent,
        infoContainer
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const service = inject("service");
        const elearningId = route.params.elearningId;
        const elearningName = route.params.elearningName;
        const rank = route.params.rank;
        const loginRank = route.params.loginRank;
        const jyukoufukaFlg = ref();
        const priceApplication = route.params.priceApplication;
        const alertMessage = ref();
        const rate = ref(null);
        const tableData = ref([]);
        const elearningType = ref();
        const typeObject = ref();
        const evaluationContentList = ref();
        const comprehensiveEvaluation = ref();
        const evaluationContent = ref();
        const starsNum = ref();
        const fixedPrice = ref();
        const studyTerm = ref();
        const imgDialogVisible = ref(false)
        let goUrl = 'elearning';
        let dispaly37 = false;

        if(!route.params.elearningId) {
            router.push({ name: goUrl, params: {tabSelect: 0, isFromInfo: 1}});
        }
        const buttons = ref({
            left: {
                text: '戻る',
                callBack: function() {
                    router.push({ name: goUrl, params: {tabSelect: 0, isFromInfo: 1}});
                }
            },
            centre: {
                text: '申し込む',
                callBack: (tableData) => {
                if(jyukoufukaFlg.value == 1){
                    alert("全体管理者または所属組織管理者のため、研修申込できません。");
                    return;
                }
                let formdata = {elearningId: elearningId, elearningName: elearningName, loginRank: loginRank, rank: rank}

                if(priceApplication == "有料" || priceApplication == "有料申込済"){
                    alertMessage.value = "有料の研修のため課金が発生しますが、申込してよろしいでしょうか？ \n" + "定価" + formatMoney(fixedPrice.value) + "円（税抜）、研修期間は"
                    + formatStudyTerm(studyTerm.value) + "です。申込後はキャンセルできません。";
                }else if(priceApplication == "申込済"){
                    alertMessage.value = "「" + elearningName + "」は申込済みです。申込してもよろしいですか？（申込済の研修は「研修管理」から確認できます。）";
                }else{
                    alertMessage.value = "申込してもよろしいですか？";
                }
                ElMessageBox.confirm(alertMessage.value,
                    "研修",{
                        cancelButtonClass: "btn-custom-cancel",
                        confirmButtonText: "OK",
                        cancelButtonText: "キャンセル",
                        type: "warning",
                        customStyle: "white-space: pre-line",
                    }
                    ).then(() => {
                        service.elearningInfo.studyStart(formdata).then((res) => {
                            ElMessageBox({
                                cancelButtonClass: "btn-custom-cancel",
                                type:'success',
                                message: res,
                                showClose: true,
                                duration:3000,
                                showCancelButton: true,
                                cancelButtonText: "閉じる",
                                confirmButtonText: '研修を確認する',
                            }).then(() => {
                                router.push({ name: 'elgstudy', params: {tabSelect: 0, isFromInfo: 1, column: 9, order: '2'}});
                            }).catch(() => {
                            });
                        }).catch((error) => {
                        });
                    }).catch(() => {
                });

                },
            }
        })

		onBeforeMount(()=> {
		  service.common.typeObject().then((res) => {
			  typeObject.value = res;
		  }).catch((error) => {
			  console.log(error);
		  });
		  service.common.evaluationContent().then((res) => {
			  evaluationContentList.value = res;
		  }).catch((error) => {
			  console.log(error);
		  });
		})

        onMounted(()=>{
            init();
            if(typeof(route.params.fromUrl) != "undefined") {
                goUrl = route.params.fromUrl;
                localStorage.fromTopUrl = goUrl;
            } else if(localStorage.fromTopUrl) {
                goUrl = localStorage.fromTopUrl
            }
        })

        onBeforeUnmount(() => {
            localStorage.removeItem('fromTopUrl')
        })

        function init() {
            let formdata = {elearningId: elearningId, rank: rank};
            service.elearningInfo.init(formdata).then(res=>{
              jyukoufukaFlg.value = res.jyukoufukaFlg
              dispaly37 = res.dispaly37;
              tableData.value = res.data;
              elearningType.value = getLable(tableData.value.elearningTypeObject);
              comprehensiveEvaluation.value = tableData.value.comprehensiveEvaluation;
              evaluationContent.value = tableData.value.evaluationContent;
              starsNum.value = tableData.value.starsNum;
              fixedPrice.value = tableData.value.fixedPrice;
              studyTerm.value = tableData.value.studyTerm;
              if(!dispaly37){
                buttons.value.centre.show = function() { return false }
              }
            })
        }
        function getLable(value) {
			for(let i = 0;i<typeObject.value.length;i++) {
				if(value == typeObject.value[i].value) {
					return typeObject.value[i].label;
				}
			}
        }
        function getLableEvaluationContent(value) {
			for(let i = 0;i<evaluationContentList.value.length;i++) {
				if(value == evaluationContentList.value[i].value) {
					return evaluationContentList.value[i].label;
				}
			}
        }
        function formatMoney(data) {
            if(data == '' || data == null) {
                return 0;
            } else {
                return (
                (String(data).split(",").join("") || 0)
                    .toString()
                    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
                );
            }
        }
        function formatStudyTerm(data) {
            return data == 0 ? "期間制限無し" : "本日から" + data + "ヶ月";
        }
        function openImgDialog() {
            imgDialogVisible.value = true;
        }
        function closeImgDialog() {
            imgDialogVisible.value = false;
        }
        return { buttons, rate, init, tableData, getLable, typeObject, elearningType, 
            comprehensiveEvaluation,  starsNum, evaluationContent, getLableEvaluationContent, formatMoney, formatStudyTerm,
            imgDialogVisible, closeImgDialog
        }
    },
}
</script>
<style scoped>
.container {
    padding-bottom: 16px;
}
.image_file-container {
  max-width: 100%;
}

.image_file-container.small {
  max-width: 500px;
}

.image_file-container.large {
  max-width: 800px;
}

.image_file-container img {
  max-width: 500px;
  height: auto;
  transition: all 0.3s ease;
}

.image_file-container img:hover {
  cursor: pointer;
  opacity: 0.3;
  background-color: white;
}

.img_dialog-content {
  text-align: center;
}

.img_dialog-content img {
  max-width: 800px;
  height: auto;
}
</style>
