<template>
    <div class="content">
        <div class="rsk-p-xl">
        <div class="rsk-form-control">
            <h2 class="rsk-page-title rsk-mb-s">アンケート</h2>
        </div>
        <rsk-tabs :list="['テスト', 'アンケート', 'レポート']" :modelValue="1" @onChange="tabsChange" />
        <div class="rsk-panel rsk-mb-m">
            <rsk-form :model="from" v-model="form" :rules="rules" ref="formRef" cacheKey="anketoCache">
                <rsk-form-item label="アンケート名" annotation="（部分一致）" prop="anketoName">
                    <rsk-input max="50" v-model="form.anketoName" />
                </rsk-form-item>
                <rsk-form-item hintText="必須" label="ステータス" prop="status" isList>
                    <rsk-checkbox v-model="form.status" :options="[{label:'未回答', value:'0'}, {label:'回答済', value:'1'}]" />
                </rsk-form-item>
            </rsk-form>

            <rsk-form-submit>
                <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
                <rsk-button isExpand @click="clear" type="tertiary">クリア</rsk-button>
            </rsk-form-submit>
        </div>
        <rsk-table cacheKey="anketoCache" :total="tableTotal" 
                   @init="tableConfigInit" @sortChange="tableChange" 
                   @pageInfoChange="tableChange" :data="tableData" 
                   :titles="titles"  ref="tableRef"
        />
        </div>
    </div>
</template>
<script>
import { dateFtt } from '@/utils/common'
import { ref, onMounted, inject, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { checkInvalid } from '@/utils/validate'
import { full } from "@/utils/validateUtils";
import { tabsChangeEffect } from '../js/course'
export default {
    setup() {

        const formRef = ref()
        const tableRef = ref();
        const tableTotal = ref(0)
        const form = ref({
            status: [0, 1]
        })
        const titles = ref([
        {
            field:'anketoId',
            title:'ID',
            order:'asc',
            
        },
        {
            field:'anketoName',
            title:'アンケート名',
            order:'none',
        },
        {
            field:'anketoStatus',
            title:'ステータス',
            order:'none',
        },
        {
            field:'limitDate',
            title:'回答期限日',
            order:'none',
        },
        {
            field:'answerBeginTm',
            title:'回答日',
            order:'none',
        }
    ])
    
    const tableData = ref([])
    const route = useRoute();
    const router = useRouter()
    const { tabsChange } = tabsChangeEffect(router);

    onMounted(() => {
        localStorage.removeItem('anketoLessonId')
        submit();
    })
    const service = inject("service");

    const rules =  ref(
        {
            anketoName: [
                full('アンケート名は全角で入力してください'),
                { 
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe: true }
                        checkInvalid(validateData, service, callback, 'アンケート名')
                    }
                },
                {
                    max: 50,
                    message: 'アンケート名は50文字以内で入力してください。'
                },
            ],
            status: [
                {
                    validator: (rule, value, callback) => {
                        if (form.value.status.length == 0) {
                            callback(new Error("ステータスを選択してください。"));
                        } else {
                            callback();
                        }
                    },
                },
            ],
        }
    )

    function submit() {

        formRef.value.validate(form.value).then((resp) => {
            if (resp) {
                service.anketo.search({...pagingInfo, ...form.value,...columnInfo}).then(res => {
                res.list.forEach(item =>{
                    var anketoName = item.anketoName;
                    item.anketoStatus === "0" ? item.anketoStatus = "未回答" : item.anketoStatus = "回答済"
                    item.anketoName = {
                        text:item.anketoName,
                        callBack: function() {
                            if (item.studyFlg == '1') {
                                goAnswer(item.anketoId, item.lessonId);
                            } else {
                                goConfirm(item.anketoId, item.lessonId)
                            }                    
                        }
                    }
                    if (item.studyFlg == '0' && item.anketoStatus == '未回答') {
                        item.anketoName = anketoName; 
                    }
                    if(item.answerBeginTm != null) {
                        item.answerBeginTm = dateFtt('yyyy/MM/dd', item.answerBeginTm);
                    } else {
                        item.answerBeginTm = "-"
                    }

                    if(item.limitDate != null) {
                        item.limitDate = dateFtt('yyyy/MM/dd', item.limitDate);
                    } else {
                        item.limitDate = "-"
                    }
                })
                tableTotal.value = res.count
                tableData.value = res.list
                })
            }

        })
    }

    function refresh() {
        tableRef.value.clear();
    }

    let pagingInfo = {}
    let columnInfo = {}
    function tableConfigInit(sorts, paging) {
        pagingInfo = paging
        columnInfo = sorts;
    }
    var stopRequestFlag = false;
    function tableChange(sorts, paging) {     
        tableConfigInit(sorts, paging)
        if(!stopRequestFlag){
            submit()
        }
    }
    function goConfirm(anketoId, lessonId) {
        if(lessonId == null) {
            lessonId = 0;
        }
        let id = anketoId + "-" + lessonId
        router.push({
            name: 'anketoConfirm',
            params: {
                id : id
            }
        })
    }
    function goAnswer(anketoId, lessonId) {
        if(lessonId == null) {
            lessonId = 0;
        }
        let id = anketoId + "-" + lessonId;
        router.push({
            name: 'anketoAnswer',
            params: {
                id : id
            }
        })
    }

    function clear() {
      form.value = {};
      form.value.status = [0, 1];
      formRef.value.clear();
      tableRef.value.clear(); 
    }


    watch(()=>route.path,(to)=> {
        if(to !== '/listen/ant/confirm' && to !== '/listen/ant/answer') {
            stopRequestFlag = true
            formRef.value.clear();
            tableRef.value.clear();
        }
    }) 

    return {
        submit,
        clear,
        tabsChange,
        tableChange,
        form,
        tableData,
        titles,
        rules,
        tableTotal,
        pagingInfo,
        tableConfigInit,
        formRef,
        columnInfo,
        tableRef,
        refresh
    }
}
}
</script>