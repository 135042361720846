<template>
    <div class="lec-header">
        <img class="logo" src="../../assets/logo_jassa.png" />
    </div>
    <div class="line" />
    <div class="lec-body">
        <div class="lec-title">JASSAキャリアカレッジ 申込フォーム</div>

        <ol class="stepBar step3">
            <li class="step">お客さま情報の入力</li>
            <li class="step current">入力内容の確認</li>
            <li class="step">お申込み完了</li>
        </ol>

        <table class="tb-basic form sp-block">
            <tr>
                <th class="show-th">JASSA会員番号</th>

                <td><div>{{model.memberId}}</div></td>
            </tr>
            <tr>
                <th class="show-th">会社名</th>

               <td><div>{{model.company}}</div></td>
            </tr>
            <tr>
                <th class="show-th">所属</th>

                <td><div>{{model.belong}}</div></td>
            </tr>
            <tr>
                <th class="show-th">申込担当者氏名</th>

                <td><div>{{model.name}}</div></td>
            </tr>
            <tr>
                <th class="show-th">郵便番号</th>

                <td><div>{{model.zipCode}}</div></td>
            </tr>
            <tr>
                <th class="show-th">住所</th>

                <td><div>{{model.address}}</div></td>
            </tr>
            <tr>
                <th class="show-th">電話番号</th>

                <td><div>{{model.phone}}</div></td>
            </tr>
            <tr>
                <th class="show-th">E-mail</th>

                <td><div>{{model.mailAdr}}</div></td>
            </tr>
            <tr>
                <th class="show-th">請求先所属</th>

                <td><div>{{model.reBelong}}</div></td>
            </tr>
            <tr>
                <th class="show-th">請求先担当者氏名</th>

                <td><div>{{model.reName}}</div></td>
            </tr>
            <tr>
                <th class="show-th">請求先郵便番号</th>

                <td><div>{{model.reZipCode}}</div></td>
            </tr>
            <tr>
                <th class="show-th">請求先住所</th>

                <td><div>{{model.reAddress}}</div></td>
            </tr>
            <tr>
                <th class="show-th">請求先電話番号</th>

                <td><div>{{model.rePhone}}</div></td>
            </tr>
        </table>

        <div class="wrap btn-line">
            <div class="btn-wrap">
                <button class="show-btn" @click="moveToEditnew">入力に戻る</button>
            </div>
            <div class="btn-wrap">
                <button class="show-btn" @click="moveToFinish">登録</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute,useRouter } from 'vue-router'
import { onMounted, ref, inject} from 'vue'

    export default {
        name: 'jassa_show',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const model = ref({})
            const service = inject("service") 

            function moveToEditnew() {
                router.push({name:'jassa_editnew'})
            }

            function moveToFinish() {
                var res = confirm("登録してもよろしいですか？");
                if (res == true) {
                    var formData = {
                        memo: model.value.memberId,
                        comNm: model.value.company,
                        appUserDepNm: model.value.belong,
                        appUsrNm: model.value.name,
                        comZip: model.value.zipCode,
                        comAdr: model.value.address,
                        comTel: model.value.phone,
                        appUsrMlAdr: model.value.mailAdr,
                        demPst: model.value.reBelong,
                        dpsnNm: model.value.reName,
                        demZip: model.value.reZipCode,
                        demAdr: model.value.reAddress,
                        demTel: model.value.rePhone
                    }
                    service.opejassa.create(formData).then(res => {
                        localStorage.removeItem("jassaInfo");
                        router.push({name:'jassa_finish',
                            params: {
                            fromUrl: 'jassa_show'
                            }
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
                    
                }
            }

            onMounted(() => {
                if (null != route.params.model || undefined != route.params.model) {
                    model.value =  JSON.parse(route.params.model);
                    localStorage.setItem("jassaInfo", route.params.model);
                } else {
                    if (localStorage.getItem("jassaInfo")) {
                        model.value = JSON.parse(localStorage.getItem("jassaInfo"))
                    } else {
                        router.push({path:'/404'});
                    }
                }
            })

            return {
                moveToEditnew,
                moveToFinish,
                model
            }
        }
    }
</script>

<style lang="scss" src="./css/common.scss" scoped />